<template>
  <div class="w-full h-screen bg-white overflow-hidden">
    <slot />
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, onMounted } from "vue";
import { useStore } from "vuex";
import { identifyUser } from "../utils/common-js";

export default defineComponent({
  setup() {
    const store: any = useStore();
    const state = reactive({
      authUser: store.state.authUser,
    });

    onMounted(() => {
      identifyUser(state.authUser);
    });
  },
});
</script>

<style>
body {
  @apply !bg-white;
}
</style>
