export const userDashboard = {
  state: () => ({
    meetings: [],
    selectedDate: null,
  }),
  getters: {},
  mutations: {
    setMeetings(state, { data, selectedDate }) {
      state.meetings = data;
      state.selectedDate = selectedDate;
    },
  },
  actions: {
    setMeetings({ commit }, { data, selectedDate }) {
      commit("setMeetings", { data, selectedDate });
    },
  },
};
