<template>
  <CModal size="small" :show="state.confirmingAction">
    <h2 class="font-semibold" v-text="state.actionMessage" />
    <p v-show="state.actionDescription" class="text-gray-500 text-xs mt-1" v-text="state.actionDescription" />
    <template #footer>
      <div class="flex items-center justify-between space-x-2">
        <CButton type="button" name="secondaryButton" @click="cancelAction">
          Cancel
        </CButton>
        <CButton type="button" name="primaryButton" @click="proceedWithAction">
          Confirm
        </CButton>
      </div>
    </template>
  </CModal>
</template>

<script lang="ts" setup>
import { useConfirmActionStore } from "./store";
import CButton from "../button/CButton.vue";
import CModal from "../modal/CModal.vue";
const { state, proceedWithAction, cancelAction } = useConfirmActionStore();
</script>
