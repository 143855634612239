import gql from "graphql-tag";
import { DocumentNode } from "graphql";
import { BaseApi } from "./base";
import { TSelectUserOption, TUserLicenseConstraints, User, UserRoleEnum, TMomentumUser } from "../types/user";
import { parseTag } from "../utils/common-js";
import { IntegrationServiceEnum, ServiceStatusEnum } from "../types/integrations";
import { momentumApiUrl } from "../utils/import-env-vars";
import { TSelectOption } from "../components/library/select/types";

interface IGetTeamMembersVariables {
  searchTerm: string;
  limit: number;
  offset: number;
  roleWhere:
    | {
        _in: UserRoleEnum[];
      }
    | {};
  salesforceRoleWhere:
    | {
        _in: number[];
      }
    | {};
  integrationWhere?: {
    _and: {
      status: {
        _eq: ServiceStatusEnum;
      };
      service: {
        _in: string[];
      };
    };
  };
  userLicenseWhere: {
    _or?: {
      [key: string]: {
        _eq: boolean;
      };
    }[];
  };
}

interface TeamMemberBuildConfig {
  integrationsLength: number;
  variables: IGetTeamMembersVariables;
  aiLicenseSettingsLength?: number;
}

interface LimitOffsetVariables {
  limit: number;
  offset: number;
}

const UserFragment = `
  fragment User on user {
    id
    email
    fullName
    hasCompletedSignup
    integrations {
      service
      status
      useForOrganization
    }
    picture
    role
    slackUserName
    salesforceUserRole {
      name
    }
    userInvitations(order_by: { lastSentAt: desc }) {
      lastSentAt
      status
      service
    }
    userLicense {
      emailImport
      textMessageChatImport
      meetingBotRecordZoomSources
      meetingBotRecordNonZoomSources
      meetingBotRecordExternallyHostedMeetings
      meetingImportAndRecordGoogleCalendarSources
      meetingImportAndRecordZoomSources
      meetingImportChorusSources
      meetingImportGongSources
      meetingImportWingmanSources
      meetingImportWiserSources
    }
    type
  }
`;

export class UserApi extends BaseApi {
  async getSlackUsersBySlackIds(slackIds: string[]): Promise<TSelectUserOption[]> {
    type TVariables = { slackIds: string[] };
    type TData = { user: User[] };
    const query: DocumentNode = gql`
      query GetSlackUsersBySlackIds($slackIds: [String!]) {
        user(order_by: { fullName: asc }, where: { slackUserId: { _in: $slackIds } }) {
          id
          slackUserId
          email
          name
          fullName
          picture
          slackUserProfile
          isExternal
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { slackIds });
      const users = transformMomentumUser(result.user);
      return users;
    } catch (error) {
      this.handleError(error);
    }
  }
  //TODO: we need to move to a better way to fetching users instead of fetching all of them but this is out of scope for this PR
  async getSlackUsers(): Promise<TSelectUserOption[]> {
    type TData = { user: User[] };
    const query: DocumentNode = gql`
      query GetSlackUsers {
        user(
          order_by: { fullName: asc }
          where: { _and: { slackUserId: { _is_null: false }, isExternal: { _eq: false } } }
        ) {
          id
          slackUserId
          email
          name
          fullName
          picture
          slackUserProfile
          isExternal
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, null>(query);
      const users = transformMomentumUser(result.user);
      return users;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getSlackUser(searchTerm: string): Promise<TSelectUserOption> {
    type TVariables = { searchTerm: string };
    type TData = { user: User[] };

    const query: DocumentNode = gql`
      query SearchForSlackUser($searchTerm: String = "") {
        user(
          order_by: { fullName: asc }
          where: {
            _or: [{ email: { _eq: $searchTerm } }, { slackUserId: { _eq: $searchTerm } }]
            _and: { slackUserId: { _is_null: false }, _and: { isExternal: { _eq: false } } }
          }
        ) {
          id
          slackUserId
          email
          name
          fullName
          picture
          slackUserProfile
          isExternal
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { searchTerm });
      const user = transformMomentumUser(result.user);
      return user[0];
    } catch (error) {
      this.handleError(error);
    }
  }

  async searchSlackUsers(search: string, limit: number = 20): Promise<TSelectUserOption[]> {
    type TVariables = { searchTerm: string; limit: number };
    type TData = { user: User[] };
    const query: DocumentNode = gql`
      query SearchSlackUsers($searchTerm: String = "", $limit: Int = 10) {
        user(
          order_by: { fullName: asc }
          where: {
            _or: [{ email: { _ilike: $searchTerm } }, { fullName: { _ilike: $searchTerm } }]
            _and: { slackUserId: { _is_null: false }, email: { _is_null: false }, _and: { isExternal: { _eq: false } } }
          }
          limit: $limit
        ) {
          id
          slackUserId
          email
          name
          fullName
          picture
          slackUserProfile
          isExternal
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { searchTerm: `%${search || ""}%`, limit });
      return transformMomentumUser(result.user);
    } catch (error) {
      this.handleError(error);
    }
  }

  async searchSlackUsersWithAiLicenseSettings(search: string, limit: number = 20): Promise<TSelectUserOption[]> {
    type TVariables = { searchTerm: string; limit: number };
    type TData = { user: User[] };
    const query: DocumentNode = gql`
      query SearchSlackUsersWithAiLicenseSettings($searchTerm: String = "", $limit: Int = 10) {
        user(
          order_by: { fullName: asc }
          where: {
            _or: [{ email: { _ilike: $searchTerm } }, { fullName: { _ilike: $searchTerm } }]
            _and: {
              slackUserId: { _is_null: false }
              _and: { isExternal: { _eq: false } }
              userLicense: {
                _or: [
                  { emailImport: { _eq: true } }
                  { textMessageChatImport: { _eq: true } }
                  { meetingBotRecordZoomSources: { _eq: true } }
                  { meetingBotRecordNonZoomSources: { _eq: true } }
                  { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                  { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                  { meetingImportAndRecordZoomSources: { _eq: true } }
                  { meetingImportChorusSources: { _eq: true } }
                  { meetingImportGongSources: { _eq: true } }
                  { meetingImportSalesloftSources: { _eq: true } }
                  { meetingImportWingmanSources: { _eq: true } }
                  { meetingImportWiserSources: { _eq: true } }
                ]
              }
            }
          }
          limit: $limit
        ) {
          id
          slackUserId
          email
          name
          fullName
          picture
          slackUserProfile
          isExternal
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { searchTerm: `%${search}%`, limit });
      return transformMomentumUser(result.user);
    } catch (error) {
      this.handleError(error);
    }
  }

  async searchMomentumUsers(searchTerm?: string): Promise<TSelectUserOption[]> {
    type TVariables = { searchTerm: string };
    type TData = { user: User[] };
    const query: DocumentNode = gql`
      query SearchMomentumUsers($searchTerm: String!) {
        user(
          order_by: { fullName: asc }
          where: {
            _and: [
              { isBot: { _eq: false } }
              { isExternal: { _eq: false } }
              { _or: [{ email: { _ilike: $searchTerm } }, { fullName: { _ilike: $searchTerm } }] }
            ]
          }
        ) {
          id
          slackUserId
          email
          fullName
          picture
          slackUserProfile
          isExternal
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { searchTerm: `%${searchTerm || ""}%` });
      return transformMomentumUser(result.user).map((user) => {
        return {
          ...user,
          label: user.momentumUser.fullName,
          value: user.momentumUser.id,
          id: user.momentumUser.id,
        };
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  async getUserIntegrations(): Promise<TSelectOption[]> {
    type TData = { user_integration: { service: string }[] };
    const query: DocumentNode = gql`
      query GetUserIntegrations {
        user_integration(distinct_on: service) {
          service
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, null>(query);
      return result.user_integration.map((integration) => {
        return {
          label: parseTag(integration.service),
          value: integration.service,
        };
      });
    } catch (error) {
      this.handleError(error);
    }
  }

  async getTeamMembers(config: TeamMemberBuildConfig): Promise<{ users: User[]; count: number }> {
    switch (true) {
      case config.aiLicenseSettingsLength > 0 && config.integrationsLength > 0:
        return await this.getMembersWithIntegrationsAndAiLicenseSettings(config);
      case config.aiLicenseSettingsLength === 0 && config.integrationsLength > 0:
        return await this.getMembersWithIntegrations(config);
      case config.aiLicenseSettingsLength > 0:
        return await this.getMembersWithAiLicenseSettings(config);
      case config.aiLicenseSettingsLength === 0:
        return await this.getMembers(config);
    }
  }

  async getMembersWithIntegrationsAndAiLicenseSettings(
    config: TeamMemberBuildConfig
  ): Promise<{ users: User[]; count: number }> {
    type TVariables = IGetTeamMembersVariables;
    type TData = { user: User[]; user_aggregate: { aggregate: { count: number } } };
    const query: DocumentNode = gql`
      query GetMembersWithIntegrationsAndAiLicenseSettings(
        $searchTerm: String!
        $roleWhere: user_role_enum_comparison_exp!
        $salesforceRoleWhere: Int_comparison_exp!
        $integrationWhere: user_integration_bool_exp!
        $userLicenseWhere: user_license_bool_exp!
        $limit: Int!
        $offset: Int!
      ) {
        user(
          order_by: { fullName: asc }
          where: {
            _and: [
              { isBot: { _eq: false } }
              { isExternal: { _eq: false } }
              { role: $roleWhere }
              { salesforceUserRoleId: $salesforceRoleWhere }
              { integrations: $integrationWhere }
              { userLicense: $userLicenseWhere }
              { _or: [{ email: { _ilike: $searchTerm } }, { fullName: { _ilike: $searchTerm } }] }
              {
                _or: [
                  { hasCompletedSignup: { _eq: true } }
                  { userInvitations: { service: { _eq: "SALESFORCE" }, status: { _eq: "pending" } } }
                  {
                    userLicense: {
                      _or: [
                        { emailImport: { _eq: true } }
                        { textMessageChatImport: { _eq: true } }
                        { meetingBotRecordZoomSources: { _eq: true } }
                        { meetingBotRecordNonZoomSources: { _eq: true } }
                        { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                        { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                        { meetingImportAndRecordZoomSources: { _eq: true } }
                        { meetingImportChorusSources: { _eq: true } }
                        { meetingImportGongSources: { _eq: true } }
                        { meetingImportSalesloftSources: { _eq: true } }
                        { meetingImportWingmanSources: { _eq: true } }
                        { meetingImportWiserSources: { _eq: true } }
                      ]
                    }
                  }
                ]
              }
            ]
          }
          limit: $limit
          offset: $offset
        ) {
          ...User
        }
        user_aggregate(
          where: {
            _and: [
              { isBot: { _eq: false } }
              { isExternal: { _eq: false } }
              { role: $roleWhere }
              { salesforceUserRoleId: $salesforceRoleWhere }
              { integrations: $integrationWhere }
              { userLicense: $userLicenseWhere }
              { _or: [{ email: { _ilike: $searchTerm } }, { fullName: { _ilike: $searchTerm } }] }
              {
                _or: [
                  { hasCompletedSignup: { _eq: true } }
                  { userInvitations: { service: { _eq: "SALESFORCE" }, status: { _eq: "pending" } } }
                  {
                    userLicense: {
                      _or: [
                        { emailImport: { _eq: true } }
                        { textMessageChatImport: { _eq: true } }
                        { meetingBotRecordZoomSources: { _eq: true } }
                        { meetingBotRecordNonZoomSources: { _eq: true } }
                        { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                        { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                        { meetingImportAndRecordZoomSources: { _eq: true } }
                        { meetingImportChorusSources: { _eq: true } }
                        { meetingImportGongSources: { _eq: true } }
                        { meetingImportSalesloftSources: { _eq: true } }
                        { meetingImportWingmanSources: { _eq: true } }
                        { meetingImportWiserSources: { _eq: true } }
                      ]
                    }
                  }
                ]
              }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
      ${UserFragment}
    `;
    try {
      const result: TData = await this.query<TData, TVariables>(query, config.variables);
      return { users: result.user, count: result.user_aggregate.aggregate.count };
    } catch (error) {
      this.handleError(error);
    }
  }

  async getMembersWithIntegrations(config: TeamMemberBuildConfig): Promise<{ users: User[]; count: number }> {
    type TVariables = IGetTeamMembersVariables;
    type TData = { user: User[]; user_aggregate: { aggregate: { count: number } } };
    const query: DocumentNode = gql`
      query GetMembersWithIntegrations(
        $searchTerm: String!
        $roleWhere: user_role_enum_comparison_exp!
        $salesforceRoleWhere: Int_comparison_exp!
        $integrationWhere: user_integration_bool_exp!
        $limit: Int!
        $offset: Int!
      ) {
        user(
          order_by: { fullName: asc }
          where: {
            _and: [
              { isBot: { _eq: false } }
              { isExternal: { _eq: false } }
              { role: $roleWhere }
              { salesforceUserRoleId: $salesforceRoleWhere }
              { integrations: $integrationWhere }
              { _or: [{ email: { _ilike: $searchTerm } }, { fullName: { _ilike: $searchTerm } }] }
              {
                _or: [
                  { hasCompletedSignup: { _eq: true } }
                  { userInvitations: { service: { _eq: "SALESFORCE" }, status: { _eq: "pending" } } }
                  {
                    userLicense: {
                      _or: [
                        { emailImport: { _eq: true } }
                        { textMessageChatImport: { _eq: true } }
                        { meetingBotRecordZoomSources: { _eq: true } }
                        { meetingBotRecordNonZoomSources: { _eq: true } }
                        { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                        { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                        { meetingImportAndRecordZoomSources: { _eq: true } }
                        { meetingImportChorusSources: { _eq: true } }
                        { meetingImportGongSources: { _eq: true } }
                        { meetingImportSalesloftSources: { _eq: true } }
                        { meetingImportWingmanSources: { _eq: true } }
                        { meetingImportWiserSources: { _eq: true } }
                      ]
                    }
                  }
                ]
              }
            ]
          }
          limit: $limit
          offset: $offset
        ) {
          ...User
        }
        user_aggregate(
          where: {
            _and: [
              { isBot: { _eq: false } }
              { isExternal: { _eq: false } }
              { role: $roleWhere }
              { salesforceUserRoleId: $salesforceRoleWhere }
              { integrations: $integrationWhere }
              { _or: [{ email: { _ilike: $searchTerm } }, { fullName: { _ilike: $searchTerm } }] }
              {
                _or: [
                  { hasCompletedSignup: { _eq: true } }
                  { userInvitations: { service: { _eq: "SALESFORCE" }, status: { _eq: "pending" } } }
                  {
                    userLicense: {
                      _or: [
                        { emailImport: { _eq: true } }
                        { textMessageChatImport: { _eq: true } }
                        { meetingBotRecordZoomSources: { _eq: true } }
                        { meetingBotRecordNonZoomSources: { _eq: true } }
                        { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                        { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                        { meetingImportAndRecordZoomSources: { _eq: true } }
                        { meetingImportChorusSources: { _eq: true } }
                        { meetingImportGongSources: { _eq: true } }
                        { meetingImportSalesloftSources: { _eq: true } }
                        { meetingImportWingmanSources: { _eq: true } }
                        { meetingImportWiserSources: { _eq: true } }
                      ]
                    }
                  }
                ]
              }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
      ${UserFragment}
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, config.variables);
      return { users: result.user, count: result.user_aggregate.aggregate.count };
    } catch (error) {
      this.handleError(error);
    }
  }

  async getMembersWithAiLicenseSettings(config: TeamMemberBuildConfig): Promise<{ users: User[]; count: number }> {
    type TVariables = {
      searchTerm: string;
      limit: number;
      offset: number;
      roleWhere: any;
      salesforceRoleWhere: any;
      userLicenseWhere: any;
    };
    type TData = { user: User[]; user_aggregate: { aggregate: { count: number } } };
    const query: DocumentNode = gql`
      query GetMembersWithAiLicenseSettings(
        $searchTerm: String!
        $roleWhere: user_role_enum_comparison_exp!
        $salesforceRoleWhere: Int_comparison_exp!
        $userLicenseWhere: user_license_bool_exp!
        $limit: Int!
        $offset: Int!
      ) {
        user(
          order_by: { fullName: asc }
          where: {
            _and: [
              { isBot: { _eq: false } }
              { isExternal: { _eq: false } }
              { role: $roleWhere }
              { salesforceUserRoleId: $salesforceRoleWhere }
              { userLicense: $userLicenseWhere }
              { _or: [{ email: { _ilike: $searchTerm } }, { fullName: { _ilike: $searchTerm } }] }
              {
                _or: [
                  { hasCompletedSignup: { _eq: true } }
                  { userInvitations: { service: { _eq: "SALESFORCE" }, status: { _eq: "pending" } } }
                  {
                    userLicense: {
                      _or: [
                        { emailImport: { _eq: true } }
                        { textMessageChatImport: { _eq: true } }
                        { meetingBotRecordZoomSources: { _eq: true } }
                        { meetingBotRecordNonZoomSources: { _eq: true } }
                        { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                        { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                        { meetingImportAndRecordZoomSources: { _eq: true } }
                        { meetingImportChorusSources: { _eq: true } }
                        { meetingImportGongSources: { _eq: true } }
                        { meetingImportSalesloftSources: { _eq: true } }
                        { meetingImportWingmanSources: { _eq: true } }
                        { meetingImportWiserSources: { _eq: true } }
                      ]
                    }
                  }
                ]
              }
            ]
          }
          limit: $limit
          offset: $offset
        ) {
          ...User
        }
        user_aggregate(
          where: {
            _and: [
              { isBot: { _eq: false } }
              { isExternal: { _eq: false } }
              { role: $roleWhere }
              { salesforceUserRoleId: $salesforceRoleWhere }
              { userLicense: $userLicenseWhere }
              { _or: [{ email: { _ilike: $searchTerm } }, { fullName: { _ilike: $searchTerm } }] }
              {
                _or: [
                  { hasCompletedSignup: { _eq: true } }
                  { userInvitations: { service: { _eq: "SALESFORCE" }, status: { _eq: "pending" } } }
                  {
                    userLicense: {
                      _or: [
                        { emailImport: { _eq: true } }
                        { textMessageChatImport: { _eq: true } }
                        { meetingBotRecordZoomSources: { _eq: true } }
                        { meetingBotRecordNonZoomSources: { _eq: true } }
                        { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                        { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                        { meetingImportAndRecordZoomSources: { _eq: true } }
                        { meetingImportChorusSources: { _eq: true } }
                        { meetingImportGongSources: { _eq: true } }
                        { meetingImportSalesloftSources: { _eq: true } }
                        { meetingImportWingmanSources: { _eq: true } }
                        { meetingImportWiserSources: { _eq: true } }
                      ]
                    }
                  }
                ]
              }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
      ${UserFragment}
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, config.variables);
      return { users: result.user, count: result.user_aggregate.aggregate.count };
    } catch (error) {
      this.handleError(error);
    }
  }

  async getMembers(config: TeamMemberBuildConfig): Promise<{ users: User[]; count: number }> {
    type TVariables = { searchTerm: string; limit: number; offset: number; roleWhere: any; salesforceRoleWhere: any };
    type TData = { user: User[]; user_aggregate: { aggregate: { count: number } } };
    const query: DocumentNode = gql`
      query GetMembers(
        $searchTerm: String!
        $roleWhere: user_role_enum_comparison_exp!
        $salesforceRoleWhere: Int_comparison_exp!
        $limit: Int!
        $offset: Int!
      ) {
        user(
          order_by: { fullName: asc }
          where: {
            _and: [
              { isBot: { _eq: false } }
              { isExternal: { _eq: false } }
              { role: $roleWhere }
              { salesforceUserRoleId: $salesforceRoleWhere }
              { _or: [{ email: { _ilike: $searchTerm } }, { fullName: { _ilike: $searchTerm } }] }
              {
                _or: [
                  { hasCompletedSignup: { _eq: true } }
                  { userInvitations: { service: { _eq: "SALESFORCE" }, status: { _eq: "pending" } } }
                  {
                    userLicense: {
                      _or: [
                        { emailImport: { _eq: true } }
                        { textMessageChatImport: { _eq: true } }
                        { meetingBotRecordZoomSources: { _eq: true } }
                        { meetingBotRecordNonZoomSources: { _eq: true } }
                        { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                        { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                        { meetingImportAndRecordZoomSources: { _eq: true } }
                        { meetingImportChorusSources: { _eq: true } }
                        { meetingImportGongSources: { _eq: true } }
                        { meetingImportSalesloftSources: { _eq: true } }
                        { meetingImportWingmanSources: { _eq: true } }
                        { meetingImportWiserSources: { _eq: true } }
                      ]
                    }
                  }
                ]
              }
            ]
          }
          limit: $limit
          offset: $offset
        ) {
          ...User
        }
        user_aggregate(
          where: {
            _and: [
              { isBot: { _eq: false } }
              { isExternal: { _eq: false } }
              { role: $roleWhere }
              { salesforceUserRoleId: $salesforceRoleWhere }
              { _or: [{ email: { _ilike: $searchTerm } }, { fullName: { _ilike: $searchTerm } }] }
              {
                _or: [
                  { hasCompletedSignup: { _eq: true } }
                  { userInvitations: { service: { _eq: "SALESFORCE" }, status: { _eq: "pending" } } }
                  {
                    userLicense: {
                      _or: [
                        { emailImport: { _eq: true } }
                        { textMessageChatImport: { _eq: true } }
                        { meetingBotRecordZoomSources: { _eq: true } }
                        { meetingBotRecordNonZoomSources: { _eq: true } }
                        { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                        { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                        { meetingImportAndRecordZoomSources: { _eq: true } }
                        { meetingImportChorusSources: { _eq: true } }
                        { meetingImportGongSources: { _eq: true } }
                        { meetingImportSalesloftSources: { _eq: true } }
                        { meetingImportWingmanSources: { _eq: true } }
                        { meetingImportWiserSources: { _eq: true } }
                      ]
                    }
                  }
                ]
              }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
      ${UserFragment}
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, config.variables);
      return { users: result.user, count: result.user_aggregate.aggregate.count };
    } catch (error) {
      this.handleError(error);
    }
  }

  async getMembersWithAiWarnings(searchTerm: string): Promise<User[]> {
    type TVariables = { searchTerm: string };
    type TData = { user: User[] };
    const query: DocumentNode = gql`
      query GetMembersWithAiWarnings($searchTerm: String!) {
        user(
          order_by: { fullName: asc }
          where: {
            _and: [
              { isBot: { _eq: false } }
              { isExternal: { _eq: false } }
              { _or: [{ email: { _ilike: $searchTerm } }, { fullName: { _ilike: $searchTerm } }] }
              {
                _or: [
                  { hasCompletedSignup: { _eq: true } }
                  { userInvitations: { service: { _eq: "SALESFORCE" }, status: { _eq: "pending" } } }
                  {
                    userLicense: {
                      _or: [
                        { emailImport: { _eq: true } }
                        { textMessageChatImport: { _eq: true } }
                        { meetingBotRecordZoomSources: { _eq: true } }
                        { meetingBotRecordNonZoomSources: { _eq: true } }
                        { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                        { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                        { meetingImportAndRecordZoomSources: { _eq: true } }
                        { meetingImportChorusSources: { _eq: true } }
                        { meetingImportGongSources: { _eq: true } }
                        { meetingImportSalesloftSources: { _eq: true } }
                        { meetingImportWingmanSources: { _eq: true } }
                        { meetingImportWiserSources: { _eq: true } }
                      ]
                    }
                  }
                ]
              }
            ]
          }
        ) {
          ...User
        }
      }
      ${UserFragment}
    `;
    try {
      const result: TData = await this.query<TData, TVariables>(query, { searchTerm: `%${searchTerm}%` });
      return result.user;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getMembersWithAiSeats(variables: LimitOffsetVariables): Promise<{ users: User[]; count: number }> {
    type TVariables = { limit: number; offset: number };
    type TData = { user: User[]; user_aggregate: { aggregate: { count: number } } };
    const query: DocumentNode = gql`
      query GetMembersWithAiSeats($limit: Int!, $offset: Int!) {
        user(
          order_by: { fullName: asc }
          where: {
            _and: [
              { isBot: { _eq: false } }
              { isExternal: { _eq: false } }
              {
                userLicense: {
                  _or: [
                    { emailImport: { _eq: true } }
                    { textMessageChatImport: { _eq: true } }
                    { meetingBotRecordZoomSources: { _eq: true } }
                    { meetingBotRecordNonZoomSources: { _eq: true } }
                    { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                    { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                    { meetingImportAndRecordZoomSources: { _eq: true } }
                    { meetingImportChorusSources: { _eq: true } }
                    { meetingImportGongSources: { _eq: true } }
                    { meetingImportSalesloftSources: { _eq: true } }
                    { meetingImportWingmanSources: { _eq: true } }
                    { meetingImportWiserSources: { _eq: true } }
                  ]
                }
              }
            ]
          }
          limit: $limit
          offset: $offset
        ) {
          ...User
        }
        user_aggregate(
          where: {
            _and: [
              { isBot: { _eq: false } }
              { isExternal: { _eq: false } }
              {
                userLicense: {
                  _or: [
                    { emailImport: { _eq: true } }
                    { textMessageChatImport: { _eq: true } }
                    { meetingBotRecordZoomSources: { _eq: true } }
                    { meetingBotRecordNonZoomSources: { _eq: true } }
                    { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                    { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                    { meetingImportAndRecordZoomSources: { _eq: true } }
                    { meetingImportChorusSources: { _eq: true } }
                    { meetingImportGongSources: { _eq: true } }
                    { meetingImportSalesloftSources: { _eq: true } }
                    { meetingImportWingmanSources: { _eq: true } }
                    { meetingImportWiserSources: { _eq: true } }
                  ]
                }
              }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
      ${UserFragment}
    `;
    try {
      const result: TData = await this.query<TData, TVariables>(query, variables);
      return { users: result.user, count: result.user_aggregate.aggregate.count };
    } catch (error) {
      this.handleError(error);
    }
  }

  async getNonMomentumUsersToInvite(searchTerm: string, offset: number, limit: number): Promise<User[]> {
    type TVariables = { searchTerm: string; offset: number; limit: number };
    type TData = { user: User[] };
    const query: DocumentNode = gql`
      query GetNonMomentumUsersToInvite($searchTerm: String!, $offset: Int!, $limit: Int!) {
        user(
          order_by: { fullName: asc }
          where: {
            _and: [
              { isBot: { _eq: false } }
              { isExternal: { _eq: false } }
              { _or: [{ email: { _ilike: $searchTerm } }, { fullName: { _ilike: $searchTerm } }] }
              {
                _not: {
                  _or: [
                    { hasCompletedSignup: { _eq: true } }
                    { userInvitations: { service: { _eq: "SALESFORCE" }, status: { _eq: "pending" } } }
                    {
                      userLicense: {
                        _or: [
                          { emailImport: { _eq: true } }
                          { textMessageChatImport: { _eq: true } }
                          { meetingBotRecordZoomSources: { _eq: true } }
                          { meetingBotRecordNonZoomSources: { _eq: true } }
                          { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                          { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                          { meetingImportAndRecordZoomSources: { _eq: true } }
                          { meetingImportChorusSources: { _eq: true } }
                          { meetingImportGongSources: { _eq: true } }
                          { meetingImportSalesloftSources: { _eq: true } }
                          { meetingImportWingmanSources: { _eq: true } }
                          { meetingImportWiserSources: { _eq: true } }
                        ]
                      }
                    }
                  ]
                }
              }
            ]
          }
          offset: $offset
          limit: $limit
        ) {
          ...User
        }
      }
      ${UserFragment}
    `;
    try {
      const result: TData = await this.query<TData, TVariables>(query, {
        searchTerm: `%${searchTerm}%`,
        offset,
        limit,
      });
      return result.user;
    } catch (error) {
      this.handleError(error);
    }
  }

  async inviteUsers(ids: number[]): Promise<boolean> {
    type TVariables = { ids: number[] };
    type TData = { momentum: { core: { inviteUsers: boolean } } };
    const query: DocumentNode = gql`
      mutation InviteUsers($ids: [Int!]!) {
        momentum {
          core {
            inviteUsers(userIds: $ids)
          }
        }
      }
    `;
    try {
      const result: TData = await this.mutate<TData, TVariables>(query, { ids });
      return result.momentum.core.inviteUsers;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getCountWithSignupOrInvitationOrAiLicenseSettings(): Promise<number> {
    type TData = { user_aggregate: { aggregate: { count: number } } };
    const query: DocumentNode = gql`
      query GetAllUsersCountWithSignupOrInvitationOrAiLicenseSettings {
        user_aggregate(
          where: {
            _or: [
              { hasCompletedSignup: { _eq: true } }
              { userInvitations: { service: { _eq: "SALESFORCE" }, status: { _eq: "pending" } } }
              {
                userLicense: {
                  _or: [
                    { emailImport: { _eq: true } }
                    { textMessageChatImport: { _eq: true } }
                    { meetingBotRecordZoomSources: { _eq: true } }
                    { meetingBotRecordNonZoomSources: { _eq: true } }
                    { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                    { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                    { meetingImportAndRecordZoomSources: { _eq: true } }
                    { meetingImportChorusSources: { _eq: true } }
                    { meetingImportGongSources: { _eq: true } }
                    { meetingImportSalesloftSources: { _eq: true } }
                    { meetingImportWingmanSources: { _eq: true } }
                    { meetingImportWiserSources: { _eq: true } }
                  ]
                }
              }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, null>(query);
      return result.user_aggregate.aggregate.count;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getCountWithAiLicenseSettings(): Promise<number> {
    type TData = { user_aggregate: { aggregate: { count: number } } };
    const query: DocumentNode = gql`
      query GetUserAggregatesWithAiLicenseSettings {
        user_aggregate(
          where: {
            isBot: { _eq: false }
            isExternal: { _eq: false }
            userLicense: {
              _or: [
                { emailImport: { _eq: true } }
                { textMessageChatImport: { _eq: true } }
                { meetingBotRecordZoomSources: { _eq: true } }
                { meetingBotRecordNonZoomSources: { _eq: true } }
                { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                { meetingImportAndRecordZoomSources: { _eq: true } }
                { meetingImportChorusSources: { _eq: true } }
                { meetingImportGongSources: { _eq: true } }
                { meetingImportSalesloftSources: { _eq: true } }
                { meetingImportWingmanSources: { _eq: true } }
                { meetingImportWiserSources: { _eq: true } }
              ]
            }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, null>(query);
      return result.user_aggregate.aggregate.count;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getCountWithRoleHavingAiLicenseSettings(role: string): Promise<number> {
    type TVariables = { role: string };
    type TData = { user_aggregate: { aggregate: { count: number } } };
    const query: DocumentNode = gql`
      query GetUserAggregatesWithRoleHavingAiLicenseSettings($role: user_role_enum!) {
        user_aggregate(
          where: {
            role: { _eq: $role }
            isBot: { _eq: false }
            isExternal: { _eq: false }
            _or: [
              { hasCompletedSignup: { _eq: true } }
              { userInvitations: { service: { _eq: "SALESFORCE" }, status: { _eq: "pending" } } }
              {
                userLicense: {
                  _or: [
                    { emailImport: { _eq: true } }
                    { textMessageChatImport: { _eq: true } }
                    { meetingBotRecordZoomSources: { _eq: true } }
                    { meetingBotRecordNonZoomSources: { _eq: true } }
                    { meetingBotRecordExternallyHostedMeetings: { _eq: true } }
                    { meetingImportAndRecordGoogleCalendarSources: { _eq: true } }
                    { meetingImportAndRecordZoomSources: { _eq: true } }
                    { meetingImportChorusSources: { _eq: true } }
                    { meetingImportGongSources: { _eq: true } }
                    { meetingImportSalesloftSources: { _eq: true } }
                    { meetingImportWingmanSources: { _eq: true } }
                    { meetingImportWiserSources: { _eq: true } }
                  ]
                }
              }
            ]
          }
        ) {
          aggregate {
            count
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { role });
      return result.user_aggregate.aggregate.count;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getCountWithIntegrationUseForOrganization(integration: string): Promise<number> {
    type TVariables = { integration: string };
    type TData = { user_aggregate: { aggregate: { count: number } } };
    const query: DocumentNode = gql`
      query GetUserAggregatesWithIntegrationUseForOrganization($integration: String!) {
        user_aggregate(
          where: {
            integrations: {
              service: { _eq: $integration }
              status: { _eq: "AUTHENTICATED" }
              useForOrganization: { _eq: true }
            }
            isBot: { _eq: false }
            isExternal: { _eq: false }
          }
        ) {
          aggregate {
            count
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { integration });
      return result.user_aggregate.aggregate.count;
    } catch (error) {
      this.handleError(error);
    }
  }

  async updateRole(userId: number, role: UserRoleEnum): Promise<boolean> {
    type TVariables = { userId: number; role: UserRoleEnum };
    type TData = { momentum: { user: { updateRole: { accepted: boolean; requestId: string } } } };
    const query: DocumentNode = gql`
      mutation mutationUpdateUserRole($userId: Int!, $role: String!) {
        momentum {
          user {
            updateRole(userId: $userId, role: $role) {
              accepted
              requestId
            }
          }
        }
      }
    `;
    try {
      const result: TData = await this.query<TData, TVariables>(query, { userId, role });
      return result.momentum.user.updateRole.accepted;
    } catch (error) {
      this.handleError(error);
    }
  }

  async updateIntegrationUseForOrganization(
    userId: number,
    service: IntegrationServiceEnum,
    useForOrganization: boolean
  ): Promise<boolean> {
    type TVariables = { userId: number; service: IntegrationServiceEnum; useForOrganization: boolean };
    type TData = {
      momentum: { integration: { updateIntegrationUseForOrganization: { accepted: boolean; requestId: string } } };
    };
    const query: DocumentNode = gql`
      mutation updateIntegrationUseForOrganization($userId: Int!, $service: String!, $useForOrganization: Boolean!) {
        momentum {
          integration {
            updateIntegrationUseForOrganization(
              userId: $userId
              service: $service
              useForOrganization: $useForOrganization
            ) {
              accepted
              requestId
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.mutate<TData, TVariables>(query, { userId, service, useForOrganization });
      return result.momentum.integration.updateIntegrationUseForOrganization.accepted;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getLicenseConstraintsForOrganization(): Promise<TUserLicenseConstraints> {
    type TData = { momentum: { organization: { userLicenseConstraints: TUserLicenseConstraints } } };
    const query: DocumentNode = gql`
      query GetLicenseConstraintsForOrganization {
        momentum {
          organization {
            userLicenseConstraints {
              chorusEnabled
              emailImport
              textMessageChatImport
              gongEnabled
              momentumRecording {
                googleMeetNativeEnabled
                zoomNativeEnabled
                botRecordZoomSources
                botRecordNonZoomSources
                botRecordExternallyHostedMeetings
              }
              salesloftEnabled
              wingmanEnabled
              wiserEnabled
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, null>(query);
      return result.momentum.organization.userLicenseConstraints;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getLicense(userId: number): Promise<TUserLicenseConstraints> {
    type TVariables = { userId: number };
    type TData = { momentum: { user: { licenseMeeting: TUserLicenseConstraints } } };
    const query: DocumentNode = gql`
      query getLicense($userId: Int!) {
        momentum {
          user {
            licenseMeeting(userId: $userId) {
              chorusEnabled
              emailImport
              textMessageChatImport
              gongEnabled
              momentumRecording {
                botRecordExternallyHostedMeetings
                botRecordNonZoomSources
                botRecordZoomSources
                googleMeetNativeEnabled
                zoomNativeEnabled
              }
              salesloftEnabled
              wingmanEnabled
              wiserEnabled
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { userId });
      return result.momentum.user.licenseMeeting;
    } catch (error) {
      this.handleError(error);
    }
  }

  async setLicense(userIds: number[], license: TUserLicenseConstraints): Promise<boolean> {
    type TVariables = { userIds: number[]; license: TUserLicenseConstraints };
    type TData = { momentum: { user: { bulkSetLicenseMeeting: { accepted: boolean } } } };
    const query: DocumentNode = gql`
      mutation SetUserLicense($userIds: [Int!]!, $license: TGqlUserLicenseMeetingInput!) {
        momentum {
          user {
            bulkSetLicenseMeeting(license: $license, userIds: $userIds) {
              accepted
            }
          }
        }
      }
    `;
    try {
      const result: TData = await this.mutate<TData, TVariables>(query, { userIds, license });
      return result.momentum.user.bulkSetLicenseMeeting.accepted;
    } catch (error) {
      this.handleError(error);
    }
  }

  async deleteMany(userIds: number[]): Promise<boolean> {
    type TVariables = { userIds: number[] };
    type TData = {
      momentum: {
        user: {
          deleteMany: {
            deleted: boolean;
            requestId: string;
          };
        };
      };
    };

    const query: DocumentNode = gql`
      mutation DeleteUsers($userIds: [Int!]!) {
        momentum {
          user {
            deleteMany(userIds: $userIds) {
              deleted
              requestId
            }
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { userIds });
      return result.momentum.user.deleteMany.deleted;
    } catch (error) {
      this.handleError(error);
    }
  }

  async get(userId: number): Promise<User> {
    type TVariables = { userId: number };
    type TData = { user: User[] };
    const query: DocumentNode = gql`
      query GetUser($userId: Int!) {
        user(where: { id: { _eq: $userId } }) {
          email
          fullName
          integrations {
            service
            status
            useForOrganization
          }
          name
          organization {
            name
          }
          picture
          role
          slackUserName
          salesforceUserRole {
            name
          }
          userLicense {
            emailImport
            textMessageChatImport
            meetingBotRecordZoomSources
            meetingBotRecordNonZoomSources
            meetingBotRecordExternallyHostedMeetings
            meetingImportAndRecordGoogleCalendarSources
            meetingImportAndRecordZoomSources
            meetingImportChorusSources
            meetingImportGongSources
            meetingImportWingmanSources
            meetingImportWiserSources
          }
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { userId });
      return result.user[0];
    } catch (error) {
      this.handleError(error);
    }
  }

  async getInfo(userId: number): Promise<User> {
    type TVariables = { userId: number };
    type TData = { user: User[] };
    const query: DocumentNode = gql`
      query GetUserInfo($userId: Int!) {
        user(where: { id: { _eq: $userId } }) {
          email
          fullName
          name
          picture
          role
          slackUserName
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { userId });
      return result.user[0];
    } catch (error) {
      this.handleError(error);
    }
  }

  async getUsers(): Promise<User[]> {
    type TData = { user: User[] };
    const query: DocumentNode = gql`
      query GetUsers {
        user(
          order_by: { fullName: asc }
          where: { _and: [{ isBot: { _eq: false } }, { isExternal: { _eq: false } }] }
          limit: 20
        ) {
          email
          fullName
          id
          name
          picture
          role
          slackUserName
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, null>(query);
      return result.user;
    } catch (error) {
      this.handleError(error);
    }
  }

  async getSalesforceIntegrationStatus(userId: number): Promise<ServiceStatusEnum> {
    type TVariables = { userId: number };
    type TData = { user_integration: { status: ServiceStatusEnum } };
    const query: DocumentNode = gql`
      query GetSalesforceIntegrationStatus($userId: Int!) {
        user_integration(where: { userId: { _eq: $userId }, service: { _eq: "SALESFORCE" } }) {
          status
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, { userId });
      return result.user_integration[0]?.status;
    } catch (error) {
      this.handleError(error);
    }
  }

  async searchUsers(options: { limit: number; searchTerm: string }): Promise<TMomentumUser[]> {
    type TVariables = { limit: number; searchTerm: string };
    type TData = { user: TMomentumUser[] };
    const query: DocumentNode = gql`
      query SearchUsers($limit: Int!, $searchTerm: String!) {
        user(where: { email: { _ilike: $searchTerm } }, limit: $limit) {
          email
          fullName
          id
        }
      }
    `;

    try {
      const result: TData = await this.query<TData, TVariables>(query, options);
      return result.user;
    } catch (error) {
      this.handleError(error);
    }
  }
}
//transform the user data to the format that the UI select component expects
export function transformMomentumUser(user: User[]): TSelectUserOption[] {
  return user.map((user) => {
    return {
      momentumUser: user,
      label: user.fullName || user.slackUserProfile.real_name,
      value: user.slackUserId,
      id: user.slackUserId,
      momentumUserId: user.id,
      disabled: false,
      type: "user",
    };
  });
}

export function findSlackUser(slackUserId: string, users: TSelectUserOption[]): TSelectUserOption {
  return users.find((user) => user.value === slackUserId);
}

export class UserRestApi {
  headers: Headers;
  constructor(authToken: string) {
    this.headers = new Headers();
    this.headers.set("Authorization", `Bearer ${authToken}`);
    this.headers.set("Content-Type", "text/csv");
  }

  async getMembers(): Promise<string> {
    try {
      const response = await fetch(`${momentumApiUrl}/data/file/userData`, {
        method: "GET",
        headers: this.headers,
      });
      return response.text();
    } catch (error) {
      console.error(error);
    }
  }
}
