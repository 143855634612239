import { defineStore } from "pinia";
import { reactive } from "vue";

export const useConfirmActionStore = defineStore("confirm-action", () => {
  const state = reactive({
    actionMessage: "",
    actionDescription: "",
    isConfirmed: false,
    confirmingAction: false,
  });

  function setConfirmingAction(message: string, options?: { description: string }): void {
    state.actionMessage = message;
    state.actionDescription = options?.description || "";
    state.confirmingAction = true;
  }

  function cancelAction(): void {
    state.isConfirmed = false;
    state.confirmingAction = false;
  }

  function proceedWithAction(): void {
    state.isConfirmed = true;
    state.confirmingAction = false;
  }

  return { state, setConfirmingAction, cancelAction, proceedWithAction };
});
